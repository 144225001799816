var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("基本信息")]},proxy:true}])},[_c('InfoPanel',{attrs:{"schema":_vm.factorySchema,"data":_vm.factory},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var value = ref.value;
return [_c('div',[_vm._v(_vm._s(value))]),_c('el-link',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":_vm.onEdit}},[_vm._v(" 编辑 ")])]}},{key:"access",fn:function(ref){
var value = ref.value;
return [_c('div',{class:[_vm.checkClipboardyStatus ? 'dotdotdot' : '']},[_vm._v(" "+_vm._s(value)+" ")]),_c('el-link',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onCopyAccessToken(value)}}},[_vm._v(" 复制 ")])]}}])}),_c('el-dialog',{attrs:{"title":"工厂名称","visible":_vm.editDialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.editDialogVisible=$event}}},[_c('el-form',{attrs:{"model":_vm.factory,"label-width":"80px"}},[_c('el-form-item',{staticStyle:{"margin":"0"},attrs:{"label":"车间名称"}},[_c('el-input',{model:{value:(_vm.factory.name),callback:function ($$v) {_vm.$set(_vm.factory, "name", $$v)},expression:"factory.name"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onEditSubmit}},[_vm._v("提交")])],1)],1)],1),_c('el-card',{staticStyle:{"margin-top":"20px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("产线")]},proxy:true}])},[_c('SchemaTable',{attrs:{"model":_vm.productLineModel,"schema":_vm.productLineSchema,"hasQuery":false,"canCreate":false,"hasAction":false,"colConfig":{
        status: { width: '120px' },
        created_at: { width: '160px' },
        $actionBtns: { width: '80px' },
      }},scopedSlots:_vm._u([{key:"overrideActionBtns",fn:function(ref){
      var row = ref.row;
return [_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"}},[_vm._v(" 详情 ")])]}}])})],1),_c('el-card',{staticStyle:{"margin-top":"20px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("工位")]},proxy:true}])},[_c('SchemaTable',{attrs:{"model":_vm.workPlaceModel,"schema":_vm.workPlaceSchema,"hasQuery":false,"canCreate":false,"hasAction":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }