<template>
  <div>
    <el-card>
      <template #header>基本信息</template>
      <InfoPanel :schema="factorySchema" :data="factory">
        <template #name="{ value }">
          <div>{{ value }}</div>
          <el-link type="primary" class="edit-btn" @click="onEdit">
            编辑
          </el-link>
        </template>
        <template #access="{ value }">
          <div :class="[checkClipboardyStatus ? 'dotdotdot' : '']">
            {{ value }}
          </div>
          <el-link
            type="primary"
            class="edit-btn"
            @click="onCopyAccessToken(value)"
          >
            复制
          </el-link>
        </template>
      </InfoPanel>
      <el-dialog title="工厂名称" :visible.sync="editDialogVisible" width="30%">
        <el-form :model="factory" label-width="80px">
          <el-form-item label="车间名称" style="margin: 0">
            <el-input v-model="factory.name" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onEditSubmit">提交</el-button>
        </span>
      </el-dialog>
    </el-card>

    <el-card style="margin-top: 20px">
      <template #header>产线</template>
      <SchemaTable
        :model="productLineModel"
        :schema="productLineSchema"
        :hasQuery="false"
        :canCreate="false"
        :hasAction="false"
        :colConfig="{
          status: { width: '120px' },
          created_at: { width: '160px' },
          $actionBtns: { width: '80px' },
        }"
      >
        <template #overrideActionBtns="{ row }">
          <el-link type="primary" class="action-link">
            详情
            <!-- <span>{{ row.id }}</span> -->
          </el-link>
        </template>
      </SchemaTable>
    </el-card>

    <el-card style="margin-top: 20px">
      <template #header>工位</template>
      <SchemaTable
        :model="workPlaceModel"
        :schema="workPlaceSchema"
        :hasQuery="false"
        :canCreate="false"
        :hasAction="false"
      />
    </el-card>
  </div>
</template>

<style>
.dotdotdot {
  display: inline-block;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.edit-btn {
  margin-left: 10px;
}

.el-card__header {
  border: none;
  padding-bottom: 0;
}
</style>

<script>
import axios from "axios";
import { uris, attachId } from "./../../api/uri";

import InfoPanel from "enn-info-panel";
import factorySchema from "./factory-detail.schema.json";

import { SchemaTable, DataModel } from "enn-schema-table";
import productLineSchema from "./productLine.schema.json";
import workPlaceSchema from "../workPlace/workPlace.schema.json";

export default {
  components: {
    InfoPanel,
    SchemaTable,
  },
  data() {
    const { id } = this.$route.params;
    return {
      factorySchema,
      factory: {},
      productLineSchema,
      productLineModel: new DataModel({
        getListApi: `${uris.productLine}?factoryId=${id}`,
        getListMap(productLine) {
          productLine.status = productLine.status ? "开启" : "关闭";
          return productLine;
        },
      }),
      workPlaceSchema,
      workPlaceModel: new DataModel({
        getListApi: `${uris.workPlace}?factoryId=${id}`,
        getListMap(workPlace) {
          workPlace.bind =
            workPlace.bind && workPlace.bind === 1 ? "已绑定" : "未绑定";
          return workPlace;
        },
      }),
      editDialogVisible: false,
      checkClipboardyStatus: true, // 用于记录剪切板是否可用
    };
  },
  created() {
    this.getFactory();
  },
  methods: {
    getFactory() {
      const { id } = this.$route.params;
      axios.get(`${uris.factory}/${id}`).then((res) => {
        const {
          data: { data: factory },
        } = res;
        factory.onoffLine = factory.onoffLine % 2 ? "在线" : "离线"; // 1-在线 2-离线
        this.factory = factory;
      });
    },
    onCopyAccessToken(content, message) {
      let aux = document.createElement("input");
      aux.setAttribute("value", content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      if (message == null) {
        this.checkClipboardyStatus = true;
        this.$message({
          message: "复制成功",
          type: "success",
        });
      } else {
        this.checkClipboardyStatus = false;
        console.log("key copy error: ", message);
        this.$message({
          message: "复制失败,请手动复制",
          type: "error",
        });
      }
    },
    onEdit() {
      this.editDialogVisible = true;
    },
    onEditSubmit() {
      const { id } = this.$route.params;
      axios
        .put(`${uris.factory}/${id}`, {
          name: this.factory.name,
        })
        .then((res) => {
          this.$message({
            message: "提交成功",
            type: "success",
          });
        });

      this.editDialogVisible = false;
    },
  },
};
</script>
